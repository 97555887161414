import Swup from "swup";
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import GLightbox from 'glightbox';

function addClickEventListener(element, clickEvent) {
    const dragTolerance = 6;
    let startX;
    let startY;

    element.addEventListener('mousedown', function (event) {
        startX = event.pageX;
        startY = event.pageY;
    });

    element.addEventListener('mouseup', function (event) {
        const diffX = Math.abs(event.pageX - startX);
        const diffY = Math.abs(event.pageY - startY);

        if (diffX < dragTolerance && diffY < dragTolerance) {
            clickEvent(event);
        }
    });
}

function debounce(callback, interval) {
    let debounceTimeoutId;
  
    return function(...args) {
      clearTimeout(debounceTimeoutId);
      debounceTimeoutId = setTimeout(() => callback.apply(this, args), interval);
    };
}

function getChildIndex(node) {
    return Array.prototype.indexOf.call(node.parentNode.childNodes, node);
}

const swup = new Swup({
    plugins: [
        new SwupBodyClassPlugin({
            prefix: 'tmp-'
        })
    ]
});

const lightbox = GLightbox({
    openEffect: 'fade',
    closeEffect: 'none',
    zoomable: false,
    moreLength: 0,
    dragToleranceX: 6,
    loop: true,
    closeOnOutsideClick: false,
});

console.log(lightbox);

let lindex = 0;

lightbox.on('open', () => {
    addClickEventListener(lightbox.slidesContainer, e => {
        if (clickedLeftSideOf(e)) {
            debounce(lightbox.goToSlide(lindex - 1), 30);
        } else {
            debounce(lightbox.goToSlide(lindex + 1), 30);
        }
    })
})

function clickedLeftSideOf(e) {
    const clickTarget = e.target;
    const clickTargetWidth = clickTarget.offsetWidth;
    const xCoordInClickTarget = e.clientX - clickTarget.getBoundingClientRect().left;
    if (clickTargetWidth / 2 > xCoordInClickTarget) {
      return true;
    } else {
      return false;
    }
}

lightbox.on('slide_changed', () => {
    lindex = lightbox.index;
    // console.log(lightbox);
    // addClickEventListener(lightbox.activeSlide, () => {
    //     console.log('next');
    //     debounce(lightbox.nextSlide(), 30);
    // })
})

init();

swup.on('contentReplaced', () => {
    invert();
    init();
});

function init() {
    window.scrollTo(0, 0);
    lightbox.reload();
    if (document.querySelector('.about-logo')) {
        document.querySelector('.about-logo').addEventListener('click', () => {
            invert();
        })
    }
}

function invert() {
    console.log('invert');
    document.body.classList.toggle('black');
}